// First we need to import axios.js
import axios from "axios";
//demo server
// export const accountsConfig = axios.create({
//     baseURL: "https://developers-dett-prebooking.talrop.works/",
// });

//live server
export const accountsConfig = axios.create({
    baseURL: "https://api-pre-booking.dett.app/",
});
